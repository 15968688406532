import ApiUtils from "@/utils/ApiUtils"
import React, { forwardRef, useImperativeHandle, useState } from "react"

const Form = forwardRef((props, ref) => {

  const onFormSubmit = (e) => {
    if (!props.id) return
    if (props.button) {
      const idParts = props.id.replaceAll("_form","").split("_")
      ApiUtils.logEvent("btn_click", idParts[0], idParts.length > 1 ? idParts.splice(1).join("_") : null, {}, {})
    } else {
      ApiUtils.logEvent("form_submit", props.id.replaceAll("_form",""), null, {}, {})
    }
    props.onSubmit(e)
  }

  useImperativeHandle(ref, () => ({
  }))

  return <form className={props.className} id={props.id} name={props.name} onSubmit={e => onFormSubmit(e)}>
    { props.children }
  </form>

})

Form.displayName = "Form"
export default Form