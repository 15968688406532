export default function Footer(props) {

  return <section>
    <div className="footer">
      <div className="footer-block footer-left">
        { props.config.legal.copyright &&
        <div>{ props.config.legal.copyright }</div>
        }
        <a href={props.config.legal.privacy} target="_carcoverage" title="Privacy Policy">Privacy Policy</a>
        { props.config.legal.glba &&
        <a href={props.config.legal.glba} target="_carcoverage" title="Privacy Policy">GLBA Privacy</a>
        }
        { props.config.legal.terms &&
        <a href={props.config.legal.terms} target="_carcoverage" title="Terms of Service">Terms of Service</a>
        }
      </div>
      <div className="footer-block footer-right">
        <div>Powered By <a className="" target="_carcoverage" href="https://carcoverage.co" title="Powered By CarCoverage">CarCoverage</a></div>
        <div>v{ props.config.app_version }</div>
      </div>
    </div>
  </section>

}