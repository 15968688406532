import { APPEARANCE, DENT, HAZARD, KEY, MAINT, PROTECT, SERVICE, TIRE, WARRANTY, WINDSHIELD } from "@/components/Icons"
import { formatNumber } from "@/plugins/Formatters"
import moment from "moment"
import { COLOR_AFFIRM, COLOR_APPLE_PAY, COLOR_GOOGLE_PAY } from "@/components/Icons";

export const PROGRESS_PRODUCTS = 10
export const PROGRESS_CHECKOUT = 30
export const PROGRESS_CONTACT = 70
export const PROGRESS_DOWN_PAYMENT = 85
export const PROGRESS_CONTRACT = 100

export const PROGRESS_FILTERS = [
  { id: PROGRESS_PRODUCTS, label: "Products Viewed Only" },
  { id: PROGRESS_CHECKOUT + "", label: "Stopped at Checkout" },
  { id: PROGRESS_CONTACT + "", label: "Submitted Contact" },
  { id: PROGRESS_CHECKOUT + "," + PROGRESS_CONTACT, label: "Intended to Purchase" },
  { id: PROGRESS_DOWN_PAYMENT + "", label: "Paid Down & Not Completed" },
  { id: PROGRESS_DOWN_PAYMENT + "," + PROGRESS_CONTRACT, label: "Paid Down Payment" },
  { id: PROGRESS_CONTRACT + "", label: "Purchased" },
]

export const US_STATES = [
  "", "AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS",
  "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"
]

export const CATEGORIES = [
  { id: "vsc", icon: SERVICE, label: "Vehicle Service Contract" },
  { id: "wheel", icon: TIRE, label: "Tire & Wheel Protection" },
  { id: "maintenance", icon: MAINT, label: "Maintenance" },
  { id: "gap", icon: PROTECT, label: "GAP Protection" },
  { id: "paint", icon: DENT, label: "Paint & Dent Repair" },
  { id: "protection", icon: APPEARANCE, label: "Appearance Protection" },
  { id: "appearance", icon: APPEARANCE, label: "Appearance Protection"},
  { id: "tire", icon: TIRE, label: "Tire Protection" },
  { id: "hazard", icon: HAZARD, label: "Roadside Hazard Protection" },
  { id: "key", icon: KEY, label: "Key Replacement" },
  { id: "warranty", icon: WARRANTY, label: "Extended Warranty" },
  { id: "other", icon: PROTECT, label: "Other" },
  { id: "theft", icon: PROTECT, label: "Theft Protection" },
  { id: "windshield", icon: WINDSHIELD, label: "Windshield Protection" },
]

export const CATEGORY_MAP = CATEGORIES.reduce((hash, cat) => {
  hash[cat.id] = cat;
  return hash;
}, {})

export function formatDate(date, format) {
  // if (format == "dateortime") {
  //   const dt = Date.parse(date)
  //   if (dt > moment())
  // } else {
    return moment(date).format("MM/DD/YYYY")
  // }
}

export function replacePlaceholders(str, options) {
  // [v_vin], [v_year], [v_make], [v_model], [v_trim], [v_mileage], [zip], [c_fn], [c_ln], [c_n], [client]
  str = str.replaceAll("[v_vin]", options.vehicle?.vin)
  str = str.replaceAll("[v_year]", options.vehicle?.year)
  str = str.replaceAll("[v_make]", options.vehicle?.make_name)
  str = str.replaceAll("[v_model]", options.vehicle?.model_name)
  str = str.replaceAll("[v_trim]", options.vehicle?.trim_name)
  str = str.replaceAll("[v_mileage]", formatNumber(options.vehicle?.mileage))
  str = str.replaceAll("[client]", options.config?.display_name || options.config?.name)
  str = str.replaceAll("[zip]", options.vehicle?.zip_code)
  str = str.replaceAll("[c_fn]", options.customer?.first_name)
  str = str.replaceAll("[c_ln]", options.customer?.last_name)
  str = str.replaceAll("[c_n]", options.customer?.name)
  str = str.replaceAll("[company]", options.config?.name)
  return str
}

export const PT_SVGS = {
  "google_pay": COLOR_GOOGLE_PAY,
  "apple_pay": COLOR_APPLE_PAY,
  "affirm": COLOR_AFFIRM,
}
export const PT_IMG = {
  "klarna": "/cdn/images/logos/klarna.png",
  "afterpay_clearpay": "/cdn/images/logos/afterpay_clearpay.png",
  "budco": "/cdn/images/logos/budco.png",
  "paypal": "/cdn/images/logos/paypal.png",
  "visa": "/cdn/images/logos/visa.png",
  "mastercard": "/cdn/images/logos/mastercard.png",
  "amex": "/cdn/images/logos/amex.png",
}
export const PT_LABELS = {
  "google_pay": "Google Pay",
  "apple_pay": "ApplePay",
  "affirm": "Affirm",
  "klarna": "Klarna",
  "afterpay_clearpay": "Afferpay",
  "budco": "Budco Financial",
  "paypal": "Paypal",
  "visa": "Visa",
  "mastercard": "Mastercard",
  "amex": "American Express",
}
